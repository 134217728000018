@font-face {
  font-family: 'caakmoon';
  src: url('../assets/fonts/caakmoon.eot?5sctdk');
  src: url('../assets/fonts/caakmoon.eot?5sctdk#iefix') format('embedded-opentype'),
    url('../assets/fonts/caakmoon.ttf?5sctdk') format('truetype'),
    url('../assets/fonts/caakmoon.woff?5sctdk') format('woff'),
    url('../assets/fonts/caakmoon.svg?5sctdk#caakmoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'caakmoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-fi-rs-eye-f:before {
  content: "\e932";
}
.icon-fi-rs-eye-o:before {
  content: "\e934";
}
.icon-fi-rs-calendar-o:before {
  content: "\e964";
}
.icon-fi-rs-trend-f:before {
  content: "\e966";
}
.icon-fi-rs-warning-f:before {
  content: "\e963";
}
.icon-fi-rs-rss-o:before {
  content: "\e962";
}
.icon-fi-rs-bookmark-f:before {
  content: "\e960";
}
.icon-fi-rs-fire:before {
  content: "\e961";
}
.icon-fi-rs-adjust:before {
  content: "\e94e";
}
.icon-fi-rs-caak-news:before {
  content: "\e94f";
}
.icon-fi-rs-comment-f:before {
  content: "\e950";
}
.icon-fi-rs-comment-o:before {
  content: "\e951";
}
.icon-fi-rs-copy:before {
  content: "\e952";
}
.icon-fi-rs-expand:before {
  content: "\e953";
}
.icon-fi-rs-grid-f:before {
  content: "\e954";
}
.icon-fi-rs-grid-o:before {
  content: "\e955";
}
.icon-fi-rs-history:before {
  content: "\e956";
}
.icon-fi-rs-home-f:before {
  content: "\e957";
}
.icon-fi-rs-home-o:before {
  content: "\e958";
}
.icon-fi-rs-lock-f:before {
  content: "\e959";
}
.icon-fi-rs-notification-f:before {
  content: "\e95a";
}
.icon-fi-rs-reply:before {
  content: "\e95b";
}
.icon-fi-rs-search-f:before {
  content: "\e95c";
}
.icon-fi-rs-search-o:before {
  content: "\e95d";
}
.icon-fi-rs-user-o:before {
  content: "\e95e";
}
.icon-fi-rs-web:before {
  content: "\e95f";
}
.icon-fi-rs-devices:before {
  content: "\e946";
}
.icon-fi-rs-discount:before {
  content: "\e947";
}
.icon-fi-rs-handshake:before {
  content: "\e948";
}
.icon-fi-rs-location-o:before {
  content: "\e949";
}
.icon-fi-rs-mail-o:before {
  content: "\e94a";
}
.icon-fi-rs-multimedia:before {
  content: "\e94b";
}
.icon-fi-rs-user-f:before {
  content: "\e94c";
}
.icon-fi-rs-visible:before {
  content: "\e94d";
}
.icon-fi-rs-hashtag:before {
  content: "\e93c";
}
.icon-fi-rs-heart-f:before {
  content: "\e93d";
}
.icon-fi-rs-info:before {
  content: "\e93e";
}
.icon-fi-rs-list-o:before {
  content: "\e93f";
}
.icon-fi-rs-minus:before {
  content: "\e940";
}
.icon-fi-rs-shield:before {
  content: "\e941";
}
.icon-fi-rs-stories-o:before {
  content: "\e942";
}
.icon-fi-rs-time-f:before {
  content: "\e943";
}
.icon-fi-rs-time-o:before {
  content: "\e944";
}
.icon-fi-rs-tv:before {
  content: "\e945";
}
.icon-fi-rs-list-f:before {
  content: '\e937';
}
.icon-fi-rs-mail-f:before {
  content: '\e938';
}
.icon-fi-rs-editor-f:before {
  content: '\e939';
}
.icon-fi-rs-editor-o:before {
  content: '\e93a';
}
.icon-fi-rs-resend:before {
  content: '\e93b';
}
.icon-fi-rs-blind:before {
  content: '\e933';
}
.icon-fi-rs-eye:before {
  content: '\e934';
}
.icon-fi-rs-filter-f:before {
  content: '\e935';
}
.icon-fi-rs-network:before {
  content: '\e936';
}
.icon-fi-rs-add-news:before {
  content: '\e924';
}
.icon-fi-rs-article-f:before {
  content: '\e925';
}
.icon-fi-rs-check:before {
  content: '\e926';
}
.icon-fi-rs-delete:before {
  content: '\e927';
}
.icon-fi-rs-direction:before {
  content: '\e928';
}
.icon-fi-rs-image-f:before {
  content: '\e929';
}
.icon-fi-rs-link:before {
  content: '\e92a';
}
.icon-fi-rs-play-button:before {
  content: '\e92b';
}
.icon-fi-rs-save:before {
  content: '\e92c';
}
.icon-fi-rs-slideshow:before {
  content: '\e92d';
}
.icon-fi-rs-star-f:before {
  content: '\e92e';
}
.icon-fi-rs-star-o:before {
  content: '\e92f';
}
.icon-fi-rs-text-f:before {
  content: '\e930';
}
.icon-fi-rs-upload:before {
  content: '\e931';
}
.icon-fi-rs-view:before {
  content: '\e932';
}
.icon-fi-rs-mute:before {
  content: '\e920';
}
.icon-fi-rs-pause:before {
  content: '\e921';
}
.icon-fi-rs-play:before {
  content: '\e922';
}
.icon-fi-rs-volume:before {
  content: '\e923';
}
.icon-fi-rs-camera-f:before {
  content: '\e91e';
}
.icon-fi-rs-camera-o:before {
  content: '\e91f';
}
.icon-fi-rs-ads:before {
  content: '\e900';
}
.icon-fi-rs-bolt:before {
  content: '\e901';
}
.icon-fi-rs-bookmark:before {
  content: '\e902';
}
.icon-fi-rs-close:before {
  content: '\e903';
}
.icon-fi-rs-down-chevron:before {
  content: '\e904';
}
.icon-fi-rs-edit:before {
  content: '\e905';
}
.icon-fi-rs-editor:before {
  content: '\e906';
}
.icon-fi-rs-exit:before {
  content: '\e907';
}
.icon-fi-rs-fb:before {
  content: '\e908';
}
.icon-fi-rs-flag:before {
  content: '\e909';
}
.icon-fi-rs-hamburger-menu:before {
  content: '\e90a';
}
.icon-fi-rs-heart:before {
  content: '\e90b';
}
.icon-fi-rs-ig:before {
  content: '\e90c';
}
.icon-fi-rs-jor:before {
  content: '\e90d';
}
.icon-fi-rs-megaphone:before {
  content: '\e90e';
}
.icon-fi-rs-mic:before {
  content: '\e90f';
}
.icon-fi-rs-more-ver:before {
  content: '\e910';
}
.icon-fi-rs-notification:before {
  content: '\e911';
}
.icon-fi-rs-phone:before {
  content: '\e912';
}
.icon-fi-rs-plus:before {
  content: '\e913';
}
.icon-fi-rs-search:before {
  content: '\e914';
}
.icon-fi-rs-settings:before {
  content: '\e915';
}
.icon-fi-rs-share:before {
  content: '\e916';
}
.icon-fi-rs-statistic:before {
  content: '\e917';
}
.icon-fi-rs-tag:before {
  content: '\e918';
}
.icon-fi-rs-tw:before {
  content: '\e919';
}
.icon-fi-rs-user:before {
  content: '\e91a';
}
.icon-fi-rs-verified:before {
  content: '\e91b';
}
.icon-fi-rs-wave:before {
  content: '\e91c';
}
.icon-fi-rs-yt:before {
  content: '\e91d';
}
