.caak_article input,
.caak_article textarea,
.caak_article .ck-editor__editable,
.caak_article input,
.caak_article textarea,
.caak_article .ant-select {
  font-size: 13px;
}
.caak_article input + span,
.caak_article textarea + span {
  font-size: 12px;
}

.caak_block .ant-card .ant-card-head,
.caak_block .ant-card .ant-card-head-title,
.caak_block .ant-card .ant-card-extra {
  min-height: 12px;
  padding: 0;
}
.caak_block .ant-card .ant-card-body {
  padding: 8px;
}

.ck-editor__editable_inline {
  min-height: 72px;
}
.ck-editor__editable_inline p a {
  color: var(--ant-primary-color);
}
.ck-editor__editable_inline ul {
  list-style-type: disc;
  list-style-position: outside;
}
.ck-editor__editable_inline ol {
  list-style-type: decimal;
  list-style-position: outside;
}

.ck-editor__editable_inline ul li,.ck-editor__editable_inline ol li {
  margin-left: 14px;
}
